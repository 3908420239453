<template>
  <div class="corp">
    <section class="corp__intro">
      <div class="container">
        <div class="corp__intro-inner">
          <div class="corp__intro-content">
            <h1
              class="corp__intro-title"
              data-aos-duration="1000"
              data-aos="fade-down"
            >
              {{ $t("pageConstruction") }}
            </h1>
            <p
              class="corp__intro-text"
              data-aos-duration="1000"
              data-aos="fade-right"
            >
              {{ $t("usefulInformation") }}
            </p>
            <locale-router-link
              to=""
              tag="button"
              class="corp__btn corp__intro-btn"
              data-aos-duration="1000"
              data-aos="fade-right"
              >{{ $t("returnMagnum") }}</locale-router-link
            >
          </div>
          <img
            class="corp__intro-img"
            src="@/assets/img/magnum-corp/corp__intro.png"
            alt=""
            data-aos-duration="1000"
            data-aos="fade-left"
          />
        </div>
      </div>
    </section>
    <section class="corp__contacts">
      <div class="container">
        <h3 class="corp__contacts-title">{{ $t("contacts") }}</h3>
        <div class="corp__contacts-inner">
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <h6 class="corp__contacts-item--title">
              {{ $t("suppliersCorp") }}
            </h6>
            <p class="corp__contacts-item--text">
              {{ $t("togetherSuppliers") }}
            </p>
            <a
              href="https://partner.magnum.kz/auth/login"
              target="_blank"
              class="corp__contacts-item--link"
            >
              {{ $t("vendorPortal") }}</a
            >
          </div>
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-left"
          >
            <h6 class="corp__contacts-item--title">{{ $t("forTenants") }}</h6>
            <p class="corp__contacts-item--text">
              {{ $t("interestedCall") }}
            </p>

            <div
              class="corp__contacts-item--info"
              v-html="$t('corpInfo')"
            ></div>
          </div>
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <h6 class="corp__contacts-item--title">{{ $t("advertisers") }}</h6>
            <p class="corp__contacts-item--text">
              {{ $t("retailChain") }}
            </p>
            <div
              class="corp__contacts-item--info"
              data-aos-duration="1000"
              data-aos="fade-down"
            >
              <p>Нурпеисова Зарина</p>
              <p>тел: +7 (727) 339 13 50 (вн. 1337)</p>
              <p>
                e-mail:
                <a href="mailto:nurpeissova.z@magnum.kz" target="_blank"
                  >nurpeissova.z@magnum.kz</a
                >
              </p>
            </div>
            <div
              class="corp__contacts-item--info"
              data-aos-duration="1100"
              data-aos="fade-down"
            >
              <p>Пронин Никита</p>
              <p>
                e-mail:
                <a href="mailto:Pronin@magnum.kz" target="_blank"
                  >Pronin@magnum.kz</a
                >
              </p>
            </div>
            <div
              class="corp__contacts-item--info"
              data-aos-duration="1200"
              data-aos="fade-down"
            >
              <p>Ханислямова Дарья</p>
              <p>тел.:+7 (727) 339 13 50 (вн. 1058)</p>
              <p>
                e-mail:
                <a href="mailto:Hanislyamova@magnum.kz" target="_blank"
                  >Hanislyamova@magnum.kz</a
                >
              </p>
            </div>
            <div
              class="corp__contacts-item--info"
              data-aos-duration="1300"
              data-aos="fade-down"
            >
              <p>Эпова Наталия</p>
              <p>тел.:+7 (727) 339 13 50 (1780)</p>
              <p>
                e-mail:
                <a href="mailto:epova@magnum.kz" target="_blank"
                  >epova@magnum.kz</a
                >
              </p>
            </div>
          </div>
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-left"
          >
            <h6 class="corp__contacts-item--title">
              {{ $t("landlordsOwners") }}
            </h6>
            <p class="corp__contacts-item--text">
              {{ $t("magnumRetail") }}
            </p>
            <div class="corp__contacts-item--info">
              <div v-html="$t('contactsDetails')"></div>
              <p>
                <a href="mailto:arenda@magnum.kz" target="_blank"
                  >arenda@magnum.kz</a
                >
              </p>
            </div>

            <h6 class="corp__contacts-item--title corp__contacts-item--phone">
              {{ $t("helpline") }}
            </h6>
            <div
              class="corp__contacts-item--info"
              v-html="$t('helplineInfo')"
            ></div>
          </div>
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <h6 class="corp__contacts-item--title">{{ $t("requisites") }}</h6>
            <p class="corp__contacts-item--text">
              {{ $t("magnumCash&Carry") }}
            </p>
            <p class="corp__contacts-item--text">
              {{ $t("legalAddress") }}
            </p>
            <p class="corp__contacts-item--text">{{ $t("BIN") }}</p>
            <p class="corp__contacts-item--text">
              {{ $t("IIC") }}
            </p>
            <p class="corp__contacts-item--text">
              {{ $t("nationalBank") }}
            </p>
            <p class="corp__contacts-item--text">{{ $t("BIC") }}</p>
          </div>
          <!-- @@@@@@@@@@@ -->
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <h6 class="corp__contacts-item--title">
              {{ $t("wholesale") }}
            </h6>
            <p class="corp__contacts-item--text">
              {{ $t("wholesaleText") }}
            </p>
            <div class="corp__contacts-item--info">
              <div v-html="$t('wholesaleInfo')"></div>
              <p>
                <a href="mailto:opt@magnum.kz" target="_blank">opt@magnum.kz</a>
              </p>
            </div>
            <a
              href="https://magnumopt.kz/"
              target="_blank"
              class="corp__btn corp__platform-btn"
              data-aos-duration="1000"
              data-aos="fade-up"
              >{{ $t("wholesaleSite") }}</a
            >
          </div>
          <div
            class="corp__contacts-item"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            <h6 class="corp__contacts-item--title">{{ $t("tradeMagnum") }}</h6>
            <p class="corp__contacts-item--text">
              {{ $t("MagnumCashCarry") }}
            </p>
            <div class="corp__contacts-item--info">
              <p v-html="$t('tradeMagnumLink')"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const corp__intro = require("@/assets/img/magnum-corp/corp__intro.png");
export default {
  name: "MagnumCorp",
  methods: {
    getImg() {
      return corp__intro;
    },
  },
};
</script>

<style lang="scss" scoped></style>
